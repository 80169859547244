import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import PostMeta from "../components/PostMeta"
import PostCategories from "../components/PostCategories"

const PostTemplate = ({ data }) => {
  const { page } = data
  const {
    title,
    content,
    categories,
    databaseId,
    seoPost,
    date,
    slug,
    featuredImage
  } = page

  return (
    <Layout
      bodyClass={`post-template-default single single-post postid-${databaseId} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination showing-comments footer-top-visible customize-support`}
    >
      <GatsbySeo
        title={seoPost?.seoTitle} description={seoPost?.seoDescription}
        canonical={`https://vedonlyonti.app/artikkelit/${slug}/`}
      />
      <article
        className={`post-${databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
        id={`post-${databaseId}`}
      >
        <header className="entry-header has-text-align-center header-footer-group">
          {featuredImage !== null &&
            <img className="featuredImg" src={featuredImage.node.mediaItemUrl} alt={title} />
          }
          <h1
            className="entry-title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </header>
        <div className="post-inner thin">
        <div className="post-meta">
            <PostMeta title={title} date={date} />
            <PostCategories categories={categories} />
          </div>
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
        title
        content
        databaseId
        date(formatString: "DD.MM.YYYY")
        slug
        seoPost {
          seoTitle
          seoDescription
        }
        featuredImage {
          node {
            id
            mediaItemUrl
          }
        }
    }
    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`

export default PostTemplate
